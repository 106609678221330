<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CImage fluid :src="logo" height="48" width="24"/>
      </CHeaderBrand>
      <CHeaderNav>
        <AppHeaderDropdownAccnt/>
        <!-- <AppHeaderDropdownAccnt v-if="getPathArray[1]==='dashboard'"/>
        <AppHeaderBackbtn v-else/> -->
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid v-if="getPathArray[1]==='dashboard'">
      <AppBreadcrumb />
    </CContainer>
    <AppManage v-if="getPathArray[1]==='inst'" :type="getPathArray[2]" :title="'설치관리'" :items="getInstCountItems"/>
    <AppManage v-if="getPathArray[1]==='as'" :type="getPathArray[2]" :title="'AS관리'" :items="getAsCountItems"/>
    <AppManage v-if="getPathArray[2]==='notice'" :title="'공지사항'"/>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppManage from './AppManage'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderBackbtn from './AppHeaderBackbtn'
import logo from '@/assets/images/logo.png'
import { mapGetters, useStore } from "vuex";
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppHeaderBackbtn,
    AppManage
  },
  data() {
    return {
      logo: logo,
      path:  this.$route.path.split('/'),
    }
  },
  mounted(){
  },
  beforeUnmount(){
    this.$store.commit("REQUEST_INST_COUNT_LIST_INIT")
    this.$store.commit("REQUEST_AS_COUNT_LIST_INIT")
  },
  computed: {
    ...mapGetters(['getInstCountItems','getAsCountItems']),
    getPathArray(){
      return this.$route.path.split('/')
    }
  },
  methods: {
  }
}
</script>
