<template>
  <CSidebar
    class="sidebar-background"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <CFormSelect
        size="lg"
        class="common-select aside-select-box sidebar-brand-full"
        @change="onChangeBusiness($event)"
        :value="getUserBusinessSchema"
        style="padding: 0 0 0 0;"
      >
        <option v-for="(userBusiness,index) in getUserBusinessList" :key="index" :value="userBusiness.value">{{userBusiness.label}}</option>
      </CFormSelect>
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import logoNegative from '@/assets/images/logo.png'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  data() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  computed: {
		...mapGetters(['getUserBusinessList','getUserBusinessSchema']),
  },
  methods: {
    onChangeBusiness(event){
      const parameter = 
      {
        value: event.target.value
      }
      this.$store.commit("REQUEST_CHANGE_BUSINESS", parameter).then( async(data) => {
        await this.$store.dispatch('REQUEST_COMMON_DATA')
        this.$router.go();
      })
      this.$router.push("/");
    }
  }
}
</script>