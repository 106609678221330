
const pageType = 'cancel'
import {
    requestInstPage,
    requestInstSelect,
	requestInstUpdate,
} from "@/apis/inst_api";

import router from "@/router";



const state = {
    instCancelStateCd:'STE004', //취소코드
    instCancelColumn: [
        { title: '이름', key: 'instSubjectName', class: 'common-table-text left common-table-width-15' },
        { title: '전화번호', key: 'instSubjectMobile', class: 'common-table-text left common-table-width-25' },
        { title: '주소', key: 'instAddr', class: 'common-table-text left common-table-width-40' },
        { title: '취소일', key: 'instCancelDtime', class: 'common-table-text left common-table-width-20' },
    ],
    instCancelItems:[],
    instCancelTotalCount:0,
    instCancelItem:{},


	
};

const getters = {
    getInstCancelStateCd(state){
        return state.instCancelStateCd
    },
    getInstCancelColumn(state){
        return state.instCancelColumn
    },

    
    getInstCancelCancelItems(state){
        return state.instCancelItems
    },
    
    getInstCancelTotalCount(state){
        return state.instCancelTotalCount
    },
    getInstCancelItem(state){
        return state.instCancelItem
    },

};


const mutations = {
	//대상자
	REQUEST_INST_CANCEL_PAGE(state,data){
		state.instCancelItems = data.data
		state.instCancelTotalCount = data.totalCount
	},
	REQUEST_INST_CANCEL_PAGE_INIT(state){
		state.instCancelItems = []
		state.instCancelTotalCount = 0
	},
    REQUEST_INST_CANCEL_SELECT(state,data){
        state.instCancelItem = data.data
    },
	REQUEST_INST_CANCEL_SELECT_INIT(state){
        state.instCancelItem = {}
	},
};

const actions = {
	async REQUEST_INST_CANCEL_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
            
			const response = await requestInstPage(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_CANCEL_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_INST_CANCEL_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstSelect(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_CANCEL_SELECT',response.data);
			}
		}catch(e){
            console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	
};

export default { mutations, state, actions, getters };