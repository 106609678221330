import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { sendMultipartFilePost } from '@/utils/multipart-helper'

function requestInstPage(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/page`,parameter)
}

function requestInstSelect(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/select`,parameter)
}

function requestInstUpdate(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/update`,parameter)
}


function requestInstIngPdfAndImgInsert(parameter){
    const formData = new FormData()
    for ( var key in parameter.instPdf ) {
        if(parameter.instPdf[key]!==null){
            formData.append(key, parameter.instPdf[key]);
        }
    }
    for(let i=0;i<parameter.files.length;i++)
        formData.append('file'+i, parameter.files[i])
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/inst/ing/pdf_and_img/insert`, formData)
}


function requestInstIngPdfAndImgUpdate(parameter){
    const formData = new FormData()
    for ( var key in parameter.instPdf ) {
        if(parameter.instPdf[key]!==null){
            formData.append(key, parameter.instPdf[key]);
        }
    }
    const noneDeleteFileList = []
    for(let i=0;i<parameter.files.length;i++){
        if(!parameter.imges[i].loadFile){
            formData.append('file'+i, parameter.files[i])
        }else{
            noneDeleteFileList.push(parameter.imges[i].fileManagerNo)
        }
    }
    
    formData.append('noneDeleteFileList', noneDeleteFileList)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/inst/ing/pdf_and_img/update`, formData)
}

function requestInstPdfUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/ing/pdf/update`,parameter)
}


function requestInstIngPdfSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/ing/pdf/select`,parameter)
}


function requestInstCountList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/count/list`,parameter)
}

function requestInstPdfFinishTrans(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/finish-pdf/trans`,parameter)
}

function requestInstFinishFileDownload(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/finish-picture/${parameter.type}/download`,parameter.instVOList, {responseType: 'blob'})
}


export {
    requestInstPage,
    requestInstSelect,
    requestInstUpdate,
    requestInstIngPdfAndImgInsert,
    requestInstIngPdfAndImgUpdate,
    requestInstPdfUpdate,
    requestInstIngPdfSelect,
    requestInstCountList,
    requestInstPdfFinishTrans,
    requestInstFinishFileDownload
}