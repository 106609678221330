import {
    requestUserDuplicateIdCheck,
    requestAuthJoinCode,
    requestCommonData,
    requestDashboardSelect,
    requestAccountList,
    requestBusinessCodeSelect
} from "@/apis/common_api";
import {
    setAccountInfoLocalStorage
} from "@/utils/oauth";
import router from "@/router";


const state = {

    appBaseUrl:process.env.VUE_APP_BASEURL,
    joinAuthorityItems:[],
    partItems:[],
    joinBusinessItems:[],

    instExistFlag:false,
    asExistFlag:false,

    sidoItems:[],
    sigunguItems:[],
    orgmItems:[],
    menuItems:[],
    instCntItems:[],
    asCntItems:[],
    businessCodeItems:[],

    userAccountName:localStorage.getItem("userAccountName"),
    loadingModalFlag:false,
    loadingFlag:false
};

const getters = {
    getJoinAuthorityItems(state){
    return state.joinAuthorityItems
    },
    getCodeItemsWithKey(state){
        return keyword => state.partItems.filter(item =>{
        return item.codeCdGroup === keyword || item.codeCdGroup === ''
        });
    },
    getJoinBusinessItems(state){
        return state.joinBusinessItems
    },
    getAppBaseUrl(state){
        return state.appBaseUrl
    },
    getSidoItems(state){
        return state.sidoItems
    },
    getSigunguItems(state){
        return state.sigunguItems
    },
    getOrgmItems(state){
        return state.orgmItems
    },
    getMenuItems(state){
        return state.menuItems
    },
    getInstCntItems(state){
        return state.instCntItems
    },
    getAsCntItems(state){
        return state.asCntItems
    },
    
    isInstExistFlag(state){
        return state.instExistFlag
    },
    isAsExistFlag(state){
        return state.asExistFlag
    },
    getUserAccountName(state){
        return state.userAccountName
    },
    getLoadingModalFlag(state){
        return state.loadingModalFlag
    },
    getLoadingFlag(state){
        return state.loadingFlag
    },
    getBusinessCodeItemsWithKey(state){
		return (keyword1, keyword2) => state.businessCodeItems.filter(item =>{
            if(keyword2!=null){
			    return item.codeCdGroup === keyword1 && keyword2.indexOf(item.parentCd) != -1
            } else {
                return item.codeCdGroup === keyword1
            }
		});
	},
};


const mutations = {
    
    START_MODAL_LOADING(state){
        state.loadingModalFlag = true
    },
    END_MODAL_LOADING(state){
        state.loadingModalFlag = false
    },
    START_LOADING(state){
        state.loadingFlag = true
    },
    END_LOADING(state){
        state.loadingFlag = false
    },
    MAKE_COMMON_DATE(state,data){
        setAccountInfoLocalStorage(data.accountInfo)
        if(data.accountInfo!=null){
            state.userAccountName = data.accountInfo.accountName
        }
        
        const sidoList = data.sidoList
        state.sidoItems=[];
        state.sidoItems.push({label: '시도', value: '',cmmnCdGroup:''});
        for(let i=0; i<sidoList.length; i++) {
          state.sidoItems.push({
            label: sidoList[i].addrSido,
            value: sidoList[i].addrSidoCd,
          });
        }
        const sigunguList = data.sigunguList
        state.sigunguItems=[];
        state.sigunguItems.push({label: '시군구', value: '',sidoCd:'',sido:''});
        for(let i=0; i<sigunguList.length; i++) {
          state.sigunguItems.push({
            label: sigunguList[i].addrSgg,
            value: sigunguList[i].addrSigunguCd,
            sidoCd: sigunguList[i].addrSidoCd,
            sido:  sigunguList[i].addrSido,
          });
        }

        const orgList = data.orgList
        state.orgmItems=[];
        state.orgmItems.push({label: '관리기관', value: ''});
        for(let i=0; i<orgList.length; i++) {
            state.orgmItems.push({
                label: orgList[i].orgName,
                value: orgList[i].orgId,
                sigunguCd: orgList[i].addrSigunguCd,
                sidoCd: orgList[i].addrSidoCd,
            });
        }
        
        const firMenuList = data.firMenuList
        const secMenuList = data.secMenuList
        state.menuItems=[];
        state.menuItems.push(
            {
                component: 'CNavItem',
                name: '메인',
                to: '/dashboard',
                icon: 'cil-speedometer',
                badge: {
                  color: 'coustom',
                  text: 'NEW',
                },
            }
        );
        for(let i=0; i<firMenuList.length; i++) {

            if(firMenuList[i].menuPath.replace('/','')=== 'inst'){
                state.instExistFlag = true
            }
            else if(firMenuList[i].menuPath.replace('/','')=== 'as'){
                state.asExistFlag = true
            }
    
            state.menuItems.push(
                {
                  component: 'CNavGroup',
                  to: firMenuList[i].menuPath,
                  name: firMenuList[i].menuName,
                  icon: firMenuList[i].menuIcon,
                  items:[],
                }
            )
            for(let j=0; j<secMenuList.length; j++) {
                if(secMenuList[j].menuParent === firMenuList[i].menuId){
                    state.menuItems[i+1].items.push(
                        {
                            component: 'CNavItem',
                            name: secMenuList[j].menuName,
                            to: secMenuList[j].menuPath,
                        }
                    )
                }
            }
        }
        
    },
    REQUEST_COMMON_DATA_INIT(state){
      state.sidoItems=[];
      state.sigunguItems=[];
    },
    REQUEST_AUTH_JOIN_CODE(state,data){ 

        const authorityList = data.authorityList
        state.joinAuthorityItems=[];

        for(let i=0; i<authorityList.length; i++) {
            state.joinAuthorityItems.push({
            label: authorityList[i].authorityName,
            value: authorityList[i].authorityId,
            });
        }
        
        const businessList = data.businessList
        state.joinBusinessItems=[];
        for(let i=0; i<businessList.length; i++) {
            state.joinBusinessItems.push({
            label: businessList[i].businessName,
            value: businessList[i].businessSchemaName,
            });
        }

        const commonCodeList = data.commonCodeList
        state.partItems=[];
        for(let i=0; i<commonCodeList.length; i++) {
            state.partItems.push({
            label: commonCodeList[i].codeName,
            value: commonCodeList[i].codeCd,
            codeCdGroup: commonCodeList[i].codeCdGroup,
            });
        }
    },
    REQUEST_DASHBOARD_SELECT(state,data){
        
        state.instCntItems = []
        state.instCntItems.push({ title: '설치 배정', cnt: data.instSetCnt, color: 'primary'})
        state.instCntItems.push({ title: '금일 설치 완료', cnt: data.instTodayFinishCnt, color: 'primary'})
        state.instCntItems.push({ title: '설치 미완료', cnt: data.instNotFinishCnt, color: 'primary'})

        state.asCntItems = []
        state.asCntItems.push({ title: 'AS 배정', cnt: data.asSetCnt, color: 'info'})
        state.asCntItems.push({ title: '금일 AS 완료', cnt: data.asTodayFinishCnt, color: 'info'})
        state.asCntItems.push({ title: 'AS 미완료', cnt: data.asNotFinishCnt, color: 'info'})
        state.asCntItems.push({ title: '기한임박 AS', cnt: data.asImminentCnt, color: 'info'})
    },
    REQUEST_BUSINESS_CODE_SELECT(state,data){
        const businessCodeItems = data.data
        state.businessCodeItems=[];
        for(let i=0; i<businessCodeItems.length; i++) {
            if(businessCodeItems.codeUseYn){
                state.businessCodeItems.push({
                    label: businessCodeItems[i].codeName,
                    value: businessCodeItems[i].codeCd,
                    codeCdGroup: businessCodeItems[i].codeCdGroup,
                    parentCd: businessCodeItems[i].codeParentCd
                });
            }
        }
    },
};

const actions = {
    async REQUEST_USER_DUPLICATE_ID_CHECK(context,parameter){
        context.commit('START_LOADING');
        const param = {}
        try{
            const response = await requestUserDuplicateIdCheck(parameter.userVO);
    
            if(response.status === 200){
                param.data = response.data
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
        return param
    },
    async REQUEST_AUTH_JOIN_CODE(context){
        context.commit('START_LOADING');
        try{
            const response = await requestAuthJoinCode();
            if(response.status === 200){
                context.commit('REQUEST_AUTH_JOIN_CODE',response.data);
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },
	async REQUEST_COMMON_DATA(context) {
		try {
			context.commit('START_LOADING');
			const response = await requestCommonData();
            if(response.status === 200){

                context.commit('MAKE_COMMON_DATE',response.data);
            }
		} catch (e) { 
            console.log(e.response.data.resultMsg)
		}
        context.commit('END_LOADING');
	},
	async REQUEST_DASHBOARD_SELECT(context) {
		try {
			context.commit('START_LOADING');
			const response = await requestDashboardSelect();
            if(response.status === 200){
                context.commit('REQUEST_DASHBOARD_SELECT',response.data);
            }
		} catch (e) { 
            console.log(e.response.data.resultMsg)
		}
        context.commit('END_LOADING');
	},
    async REQUEST_ACCOUNT_LIST(context,parameter){
        context.commit('START_LOADING');
        try{
            const response = await requestAccountList(parameter);
            if(response.status === 200){
                context.commit('END_LOADING');
                return response
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },
    async REQUEST_BUSINESS_CODE_SELECT(context){
		context.commit('START_LOADING');
		try{
			const response = await requestBusinessCodeSelect();
			if(response.status === 200){
				context.commit('REQUEST_BUSINESS_CODE_SELECT',response.data);
			}
		}catch(e){
            console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
};

export default { mutations, state, actions, getters };