<template>
  <strong class="manager-title">{{ title }}</strong>
  <CCardBody>
    <CCardGroup style="display: flex;">

      <CCard
        v-for="(item, index) in items"
        :key="index"
        class="manager-items-card"
        :style="{ width: 100 / items.length + '%', background: item.key===type?'#b8b8b8':'#ffffff' }"
        @click="$router.push(item.path)"
      >
        <CCardBody class="manager-items-card">
          <CCardTitle class="manager-items-title">
            {{ item.title }}
          </CCardTitle>
          <CCardText class="manager-items-content">
            {{ item.cnt }}명
          </CCardText>
        </CCardBody>
      </CCard>

    </CCardGroup>
  </CCardBody>
</template>

<script>

export default {
  name: 'AppManage',
  props:['title','items','type'],
  data() {
    return {
    }
  },
}
</script>
