const pageType = 'finish'
import {
    requestInstPage,
    requestInstSelect,
	requestInstUpdate,
    requestInstPdfFinishTrans,
		requestInstFinishFileDownload
} from "@/apis/inst_api";

import router from "@/router";



const state = {
    instFinishStateCd:'STE003', //완료코드
    instFinishColumn: [
        { title: '이름', key: 'instSubjectName', class: 'common-table-text left common-table-width-15' },
        { title: '전화번호', key: 'instSubjectMobile', class: 'common-table-text left common-table-width-25' },
        { title: '주소', key: 'instAddr', class: 'common-table-text left common-table-width-40' },
		{ title: '완료일', key: 'instFinishDtime', class: 'common-table-text left common-table-width-20' },
    ],
    instFinishItems:[],
    instFinishTotalCount:0,
    instFinishItem:{},


	
};

const getters = {
    getInstFinishStateCd(state){
        return state.instFinishStateCd
    },
    getInstFinishColumn(state){
        return state.instFinishColumn
    },

    
    getInstFinishFinishItems(state){
        return state.instFinishItems
    },
    
    getInstFinishTotalCount(state){
        return state.instFinishTotalCount
    },
    getInstFinishItem(state){
        return state.instFinishItem
    },

};


const mutations = {
	//대상자
	REQUEST_INST_FINISH_PAGE(state,data){
		state.instFinishItems = data.data
		state.instFinishTotalCount = data.totalCount
	},
	REQUEST_INST_FINISH_PAGE_INIT(state){
		state.instFinishItems = []
		state.instFinishTotalCount = 0
	},
    REQUEST_INST_FINISH_SELECT(state,data){
		
        state.instFinishItem = data.data
    },
	REQUEST_INST_FINISH_SELECT_INIT(state){
        state.instFinishItem = {}
	},
};

const actions = {
	async REQUEST_INST_FINISH_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
            
			const response = await requestInstPage(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_FINISH_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_INST_FINISH_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstSelect(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_FINISH_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    
    async REQUEST_INST_PDF_FINISH_TRANS(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstPdfFinishTrans(parameter.searchInstVO);
			if(response.status === 200){
				var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
	 
				let phoneType = '';
				if ( varUA.indexOf('android') > -1) {
					//안드로이드
					phoneType= "android";
				} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
					//IOS
					phoneType= "ios";
				} else {
					//아이폰, 안드로이드 외
					phoneType= "other";
				}
				
				location.href = 'sms:'/*+전호번호 */+(phoneType == 'ios' ? '&' : '?') + 'body=설치점검표 작성 : '+encodeURIComponent(`${process.env.VUE_APP_BASEURL_MOBILE}/#/external/signatureList?value=`+ response.data.data);  
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_INST_FINISH_FILE_DOWNLOAD(context, parameter){
			context.commit('START_LOADING');
			try{
					const response = await requestInstFinishFileDownload(parameter);
					if(response.status === 200){
							const url = URL.createObjectURL(new Blob([response.data]))
								const link = document.createElement('a')
								link.href = url
								link.setAttribute('download', decodeURIComponent(response.headers.get("content-disposition").split("filename=")[1]))
								document.body.appendChild(link)
								link.click()
								link.remove()
					}
			}catch(e){
					console.log(e.response.data.resultMsg)
			}
			context.commit('END_LOADING');
	}
	
};

export default { mutations, state, actions, getters };