const setTokenInLocalStorage = tokenInfo => {
  
  localStorage.setItem("access_token", "Bearer " + tokenInfo.token);
  localStorage.setItem("refresh_token", tokenInfo.refreshToken);

  localStorage.setItem("userId", tokenInfo.userId);
  localStorage.setItem("userName", tokenInfo.userName);
  localStorage.setItem("userAuthorityId", tokenInfo.userAuthorityId);

  const businessList = tokenInfo.userBusinessMappingList
  const userBusinessMappingList = []
  for(let i=0; i<businessList.length; i++) {
    userBusinessMappingList.push({
      label: businessList[i].businessName,
      value: businessList[i].businessSchemaName,
      accountName: businessList[i].accountName,
    });
  }
  localStorage.setItem("userBusinessMappingList", JSON.stringify(userBusinessMappingList));
  localStorage.setItem("userBusinessSchema", userBusinessMappingList[0].value);
  localStorage.setItem("userBusinessName", userBusinessMappingList[0].label);
  localStorage.setItem("privacyYn", businessList[0].businessPrivacyYn);
};

// 2020-05-22, DB 설계 내용과 동일하게 field명 변경
const deleteTokenInLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("userId");
  localStorage.removeItem("userName");
  localStorage.removeItem("userAuthorityId");
  localStorage.removeItem("userBusinessMappingList");
  localStorage.removeItem("userBusinessSchema");
  localStorage.removeItem("userBusinessName");
  localStorage.removeItem("userAccountId");
  localStorage.removeItem("userAccountName");
  localStorage.removeItem("privacyYn");
};

// 2020-05-22, DB 설계 내용과 동일하게 field명 변경
const setMemberInfoLocalStorage = user => {
  localStorage.setItem("userId", user.userId);
  localStorage.setItem("userName", user.userName);
  localStorage.setItem("userAuthorityId", user.userAuthorityId);
  localStorage.setItem("userBusinessMappingList", user.userBusinessMappingList);
  
};

const setBusinessInfoLocalStorage = business => {
  localStorage.setItem("userBusinessSchema", business.value);
  localStorage.setItem("userBusinessName", business.label);
};

const setAccountInfoLocalStorage = accountInfo => {
  if(accountInfo!=null){
    localStorage.setItem("userAccountId", accountInfo.accountId)
    localStorage.setItem("userAccountName", accountInfo.accountName)
  }
}

export {
  deleteTokenInLocalStorage,
  setTokenInLocalStorage,
  setMemberInfoLocalStorage,
  setBusinessInfoLocalStorage,
  setAccountInfoLocalStorage
};
