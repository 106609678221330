<template>
  <div style="border:1px solid #c5c5c5;">
    <VueSignaturePad :width="width" :height="height" ref="signaturePad" :options="{ onEnd }"/>
  </div>
  <CRow>
    <CCol style="display:flex; justify-content:end;">
      <CButton color="secondary" variant="ghost" size="sm" @click="signClear">초기화</CButton>
    </CCol>
  </CRow>
</template>
<script>

export default {
  name: 'CommonSignaturePad',
  props: [],
  data() {
    return {
      height:'0px',
      width:'100%',
      resizedImg:''
    }
  },
  computed: {
  },
  mounted(){
    this.height = this.$refs.signaturePad.$el.clientWidth + 'px'
    this.width = (this.$refs.signaturePad.$el.clientWidth) + 'px'
    const context = this
    setTimeout(function() {
      context.$refs.signaturePad.resizeCanvas()
    }, 300);
  },
  methods: {
    signClear() {
      this.$refs.signaturePad.clearSignature()
    },
    saveSign() {
      //random한 파일 명 생성
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      this.$emit('saveSign', isEmpty,data)
    },
    onBegin() {
      console.log('=== Begin ===')
    },
    onEnd() {
      this.saveSign()
    },
  },
}
</script>

<style lang="scss">
canvas {
    color: red;
}
</style>