<template>
  <CModal
    alignment="center"
    :visible="modalFlag"
    @close="
      () => {
        modalFlag = false
      }
    "
  >
    <CModalHeader
      dismiss
      @close="
        () => {
          modalFlag = false
        }
      "
    >
      <CModalTitle>{{label}}</CModalTitle>
    </CModalHeader>
    <CModalBody>{{text}}</CModalBody>
    <CModalFooter>
      <CButton color="primary"  @click="okClick">확인</CButton>
    </CModalFooter>
  </CModal>
</template>
<script>
export default {
  name: 'CommonAlertModal',
  props: ['label', 'text'],
  data() {
    return {
      modalFlag:false
    }
  },
  mounted(){
    const context = this
    this.emitter.on('openAlertModal',(val) => {
      context.init();
      context.modalFlag = true;
		});
  },
  beforeUnmount(){
    this.emitter.off('openAlertModal')
  },
  methods: {
    init(){
    },
    okClick(){
      this.modalFlag = false
    }
  },
}
</script>

