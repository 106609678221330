
const pageType = 'ing'
import {
    requestInstPage,
    requestInstSelect,
	requestInstUpdate,
	requestInstCountList,
	
    requestInstIngPdfAndImgInsert,
	requestInstIngPdfAndImgUpdate,
	requestInstIngPdfSelect,
	requestInstPdfUpdate,
	
} from "@/apis/inst_api";

import router from "@/router";



const state = {
    instIngStateCd:'STE002', //진행코드
    instIngColumn: [
        { title: '이름', key: 'instSubjectName', class: 'common-table-text left common-table-width-15' },
        { title: '전화번호', key: 'instSubjectMobile', class: 'common-table-text left common-table-width-25' },
        { title: '주소', key: 'instAddr', class: 'common-table-text left common-table-width-40' },
		{ title: '진행일', key: 'instUserAssignDtime', class: 'common-table-text left common-table-width-20' },
    ],
    instIngItems:[],
    instIngTotalCount:0,
    instIngItem:{},
	instCountItems:[]
};

const getters = {
    getInstIngStateCd(state){
        return state.instIngStateCd
    },
    getInstIngColumn(state){
        return state.instIngColumn
    },

    
    getInstIngIngItems(state){
        return state.instIngItems
    },
    
    getInstIngTotalCount(state){
        return state.instIngTotalCount
    },
    getInstIngItem(state){
        return state.instIngItem
    },
	getInstCountItems(state){
		return state.instCountItems
	}

};


const mutations = {
	//대상자
	REQUEST_INST_ING_PAGE(state,data){
		state.instIngItems = data.data
		state.instIngTotalCount = data.totalCount
	},
	REQUEST_INST_ING_PAGE_INIT(state){
		state.instIngItems = []
		state.instIngTotalCount = 0
	},
    REQUEST_INST_ING_SELECT(state,data){
        state.instIngItem = data.data
    },
	REQUEST_INST_ING_SELECT_INIT(state){
        state.instIngItem = {}
	},
	REQUEST_INST_COUNT_LIST(state,data){
		state.instCountItems =  data.data
	},
	REQUEST_INST_COUNT_LIST_INIT(state){
		state.instCountItems = []
	}
};

const actions = {
	async REQUEST_INST_ING_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
            
			const response = await requestInstPage(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_ING_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_INST_ING_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstSelect(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_ING_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	async REQUEST_INST_ING_PDF_AND_IMG_INSERT(context,parameter){
		context.commit('START_LOADING');
		requestInstIngPdfAndImgInsert(parameter).then((response) => {
			if (response.status ===201) {
				
				parameter.context.complete(response.data.data);
			} 
		}).catch((e) => {
			console.log(e.response.data.resultMsg)
		})
		context.commit('END_LOADING')
	},
	async REQUEST_INST_ING_PDF_AND_IMG_UPDATE(context,parameter){
		context.commit('START_LOADING');
		requestInstIngPdfAndImgUpdate(parameter).then((response) => {
			if (response.status ===201) {
				
				parameter.context.complete(response.data.data);
			} 
		}).catch((e) => {
			console.log(e.response.data.resultMsg)
		})
		context.commit('END_LOADING')
	},
	async REQUEST_INST_ING_PDF_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstIngPdfSelect(parameter.instPdf);
			
			if(response.status === 200){
				parameter.context.completeGetData(response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_INST_ING_PDF_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstPdfUpdate(parameter.instPdf);
			if(response.status === 201){
				parameter.context.completeBtn1Click();
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_INST_COUNT_LIST(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstCountList(parameter.searchInstCount);
			if(response.status === 200){
				context.commit('REQUEST_INST_COUNT_LIST',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_INST_ING_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstUpdate(parameter.searchInst,pageType);
			if(response.status === 201){
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	
};

export default { mutations, state, actions, getters };