<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false" :href="diabled">
      <CAvatar :src="user" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      <CDropdownItem @click="zoomModal" > <CIcon icon="cil-user" /> 회사소개 </CDropdownItem>
      <CDropdownItem @click="userModify" > <CIcon icon="cil-user" /> 사용자정보 </CDropdownItem>
      <!-- <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem> -->
      <CDropdownDivider />
      <CDropdownItem  @click="logout"> <CIcon icon="cil-lock-locked"/> 로그아웃 </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>

  <UserModifyModal/>
  <CommonImgZoomModal :label="'회사소개'"/>

</template>

<script>
import { mapGetters } from 'vuex'
import UserModifyModal from '@/components/UserModifyModal'
import CommonImgZoomModal from '@/components/common/CommonImgZoomModal'
import user from '@/assets/images/user.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  components:{UserModifyModal,CommonImgZoomModal},
  setup() {
    return {
      user: user,
      images:[
        {path:require('../assets/images/aboutUs/aboutus_1.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_2.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_3.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_4.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_5.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_6.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_7.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_8.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_9.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_10.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_11.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_12.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_13.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_14.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_15.png'), loadFile:false},
      ]
    }
  },
  computed: {
    ...mapGetters(['getUser','getUserAccountName','getUserBusinessItems','getUserAccountItems']),
  },
  methods:{
    async userModify(){
      var loginUser = {}
      const parameter = {
        context:this,
        searchUserVO: {
            userId:this.getUser.userId,
        }
      }
      await this.$store.dispatch('REQUEST_USERINFO_SELECT', parameter).then((data) => {
        loginUser = data.data
      })

      await this.$store.dispatch('REQUEST_USERINFO_BUSINESS_LIST', parameter).then((data) => {
        loginUser.userBusinessMappingList = this.getUserBusinessItems
        loginUser.userAccountList = this.getUserAccountItems
        this.emitter.emit('loginUserModify', loginUser)
      })
    },
    zoomModal(){
      window.open(`${process.env.VUE_APP_BASEURL}/hello`);
      return;
      const param = {
        images: this.images,
        index: 0
      }
      this.emitter.emit('CommonImgZoomModal', param)
    },
    logout(){
      this.$store.dispatch('REQUEST_LOGOUT')
    }
  }
}
</script>
