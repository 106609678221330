const pageType = 'finish'
import {
    requestAsPage,
    requestAsSelect,
	requestAsUpdate,
} from "@/apis/as_api";

import router from "@/router";



const state = {
    asFinishStateCd:'STE003', //진행코드
    asFinishColumn: [
        { title: '이름', key: 'asSubjectName', class: 'common-table-text common-table-width-15' },
        { title: '전화번호', key: 'asSubjectMobile', class: 'common-table-text common-table-width-25' },
        { title: '주소', key: 'asAddr', class: 'common-table-text common-table-width-40' },
		{ title: '완료일', key: 'asFinishDtime', class: 'common-table-text common-table-width-20' },
    ],
    asFinishItems:[],
    asFinishTotalCount:0,
    asFinishItem:{},


	
};

const getters = {
    getAsFinishStateCd(state){
        return state.asFinishStateCd
    },
    getAsFinishColumn(state){
        return state.asFinishColumn
    },

    
    getAsFinishFinishItems(state){
        return state.asFinishItems
    },
    
    getAsFinishTotalCount(state){
        return state.asFinishTotalCount
    },
    getAsFinishItem(state){
        return state.asFinishItem
    },

};


const mutations = {
	//대상자
	REQUEST_AS_FINISH_PAGE(state,data){
		state.asFinishItems = data.data
		state.asFinishTotalCount = data.totalCount
	},
	REQUEST_AS_FINISH_PAGE_INIT(state){
		state.asFinishItems = []
		state.asFinishTotalCount = 0
	},
    REQUEST_AS_FINISH_SELECT(state,data){
        state.asFinishItem = data.data
		state.asFinishItem.asRequestDesc = JSON.parse(state.asFinishItem.asRequestDesc)
		let asRequestDescText = '';
		for(let i=0;i<state.asFinishItem.asRequestDesc.length;i++){
			asRequestDescText += state.asFinishItem.asRequestDesc[i].title + ' : ' + state.asFinishItem.asRequestDesc[i].value  + '\n'
		}
		state.asFinishItem.asRequestDescText = asRequestDescText
    },
	REQUEST_AS_FINISH_SELECT_INIT(state){
        state.asFinishItem = {}
	},
};

const actions = {
	async REQUEST_AS_FINISH_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestAsPage(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_FINISH_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_AS_FINISH_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsSelect(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_FINISH_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_AS_FINISH_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsUpdate(parameter.searchAs,pageType);
			if(response.status === 201){
				parameter.context.complete(parameter)
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	
};

export default { mutations, state, actions, getters };