<template>
    <CModal 
      alignment="center" 
      backdrop="static" 
      :visible="loginUserModify" 
      @close="complete"
    >
      <CModalHeader>
        <CModalTitle>사용자정보</CModalTitle>
      </CModalHeader>
  
      <CModalBody>
        <CForm class="row g-3 needs-validation">
          <CCol xs="12">
            <CFormLabel for="userId">아이디</CFormLabel>
            <CFormInput
              class="disable" 
              id="userId" 
              v-model="userVO.userId" 
              disabled />
          </CCol>

          <CCol xs="6">
            <CFormLabel for="userPassword">비밀번호</CFormLabel>
            <CFormInput 
              type="password" 
              id="userPassword" 
              v-model="userVO.userPassword" 
              feedbackInvalid="비밀번호를 입력해주세요"
              @keyup="isRePassword"
              required
              :invalid="userPasswordValid"  />
          </CCol>
          <CCol xs="6">
            <CFormLabel for="userPasswordCk">비밀번호확인</CFormLabel>
            <CFormInput 
              type="password" 
              id="userPasswordCk"
              v-model="rePassword" 
              feedbackInvalid="비밀번호확인란을 입력해주세요"
              @keyup="isRePassword"
              required
              :invalid="rePasswordValid" />
          </CCol>
          <CCol xs="6">
            <CFormLabel for="userName">사용자명</CFormLabel>
            <CFormInput 
              class="disable" 
              id="userName" 
              v-model="userVO.userName" 
              disabled />
          </CCol>
          <CCol xs="6">
            <CFormLabel for="userAuthorityId">권한</CFormLabel>
            <CFormSelect 
              id="userAuthorityId"
              size="sm" 
              :options="getJoinAuthorityItems" 
              v-model="userVO.userAuthorityId" 
              disabled />
          </CCol>
          <CCol md="12" v-if="chooseNotMasterType">
            <CFormLabel for="userBusinessList">사업단</CFormLabel>
            <Multiselect 
              id="userBusinessList"
              ref="businessSelect"
              v-model="userVO.userBusinessMappingList" 
              :options="getJoinBusinessItems"
              mode="tags"
              :searchable="true"
              :object="true" 
              disabled
              >
            </Multiselect>
          </CCol>

          <div v-if="isInstaller" style="--cui-gutter-y: 0;">
            <CCol 
              xs="6"
              class="marginTop-1"
              v-for="(data, index) in showAccountSelectBox"
              :key="index" >
              <CFormLabel for="userAccount">{{ data.label }} 거래처</CFormLabel>
              <CFormSelect
                name="userAccount"
                size="sm" 
                v-model="userVO.userAccountList[index]"
                :options="accountSelectBoxList[index]"
                disabled
              />
            </CCol>
          </div>

          <CCol md="12">
            <CFormLabel for="userMobile">핸드폰번호</CFormLabel>
            <NumberInput
              id="userMobile"
              v-model="userVO.userMobile"
              :value=userVO.userMobile 
              :maxlength="maxlength"
              feedbackInvalid="핸드폰번호를 정확히 입력해주세요"
              @keyup="isUserMobile" 
              required 
              :invalid="userMobileValid" />
          </CCol>

          <CRow v-if="isInstaller" class="marginTop-1">
            <CCol md="12">
              <CFormLabel>서명이미지</CFormLabel>
            </CCol>


          <CRow v-if="userVO.userSignPng!=null">
            <CCol :lg="4">
              <div class="square">
                <CImage class="inner" fluid :src="makePath(userVO.userSignPng)"/>
              </div>
            </CCol>
          </CRow>

            <CCol v-if="isInstaller" md="12">
              <CFormCheck inline type="radio" name="sign" value="stamp" label="도장 이미지" @change="radioChange($event)" checked/>
              <CFormCheck 
                inline type="radio" 
                name="sign" 
                value="signiture" 
                label="직접 서명" 
                @change="radioChange($event)" />
            </CCol>
            <CCol md="12">
              <div v-if="imgFlag">
                <RegImgUpload @imagUpload="imagUpload" />
              </div>
              <div v-if="signFlag">
                <CommonSignaturePad @saveSign="saveSign"/>
              </div>
            </CCol>
          </CRow>
        </CForm>

      </CModalBody>
  
      <CModalFooter>
        <CButton color="secondary" @click="complete">닫기</CButton>
        <CButton color="primary" type="submit" @click="updateUser">수정</CButton>
      </CModalFooter>
    </CModal>
</template>
  
<script>
  import myMixin from '@/mixin/mixin'
  import { mapGetters } from "vuex";
  
  import NumberInput from '@/components/common/NumberInput'
  import Multiselect from '@vueform/multiselect'
  import CommonSignaturePad from '@/components/common/CommonSignaturePad'
  import imageCompression from 'browser-image-compression'
  import RegImgUpload from '@/components/pages/modal/RegImgUpload'

  export default {
    name: "Search",
    mixins: [myMixin],
    props:['getData'],
    components:{NumberInput, Multiselect,CommonSignaturePad,imageCompression,RegImgUpload},
    data () {
      return {
        maxlength:11,
        loginUserModify:false,

        userVO:{
            userId: '',
            userPassword:'',
            userName:'',
            userAuthorityId:'',
            userMobile:'',
            userBusinessMappingList:[],
            userAccountList:[],
            userTempPasswordYn:0
        },

        userBusinessListTemp: [],
        accountSelectBoxList: [],

        rePassword:'',
        userIdExistCheck:false,

        userAuthorityIdValid: false,
        userPasswordValid:false,
        rePasswordValid: false,
        userMobileValid: false,

        imgFlag : true,
        signFlag: false,

        fileUploadFlag: false,
      }
    },
    created() {  
        this.$store.dispatch("REQUEST_AUTH_JOIN_CODE")
    },
    mounted(){
        this.emitter.on('loginUserModify', async val => {
        this.init()
        this.userVO = JSON.parse(JSON.stringify(val))
        this.userVO.userPassword = ''
        this.userVO.userTempPasswordYn = 0
        this.userBusinessListTemp = this.userVO.userBusinessMappingList

        await this.loadUserAccount();

        this.loginUserModify = true
      });
    },
  beforeUnmount(){
      this.emitter.off('loginUserModify');
  },
  computed:{
    ...mapGetters(['getAppBaseUrl','getJoinAuthorityItems','getJoinBusinessItems','getLoginUserAuthorityId']),
    chooseNotMasterType(){
        if(this.userVO.userAuthorityId!=='' && this.userVO.userAuthorityId!=='1'){
            return true
        }
        this.userVO.userBusinessMappingList = []
        return false
    },
    getStatusItems(){
        const statusItems = this.$store.getters.getCodeItemsWithKey('APPROVED.STATECD')
        return  statusItems;
    },
    isInstaller(){
      //거래처관리자, 설치기사
      if(this.userVO.userAuthorityId === '3'||this.userVO.userAuthorityId === '4'){
        return true
      }
      this.accountSelectBoxList= []
      this.userVO.userAccountList = []
      return false
    },
    showAccountSelectBox() {
      return this.userVO.userBusinessMappingList
    },
  },
  methods: { 
    init(){
      this.userVO={
        userId: '',
        userPassword:'',
        userName:'',
        userAuthorityId:'',
        userMobile:'',
        userBusinessMappingList:[],
        userAccountList:[],
        userTempPasswordYn:0
      } 

      this.userBusinessListTemp= []
      this.accountSelectBoxList= []

      this.rePassword= ''
      this.userIdExistCheck= false

      this.userAuthorityIdValid= false
      this.userPasswordValid= false
      this.rePasswordValid= false
      this.userMobileValid= false

      this.imgFlag = true
      this.signFlag = false

      this.fileUploadFlag = false
    },
    async loadUserAccount(){
      for(const data of this.userVO.userBusinessMappingList){
        const parameter = {
            businessSchemaName: data.value
        }
        await this.$store.dispatch("REQUEST_ACCOUNT_LIST", parameter).then(data => {
            data.data.unshift({label:'선택',value:''})
            this.accountSelectBoxList.push(data.data)
        })
      }
    },

    //validation ckeck
    isRePassword(){
        if(this.userVO.userPassword !== '' && this.rePassword === ''){
            this.rePasswordValid = true
            return this.rePasswordValid
        }else if(this.userVO.userPassword === '' && this.rePassword !== ''){
            this.userPasswordValid = true
            return this.userPasswordValid
        }else{
            this.userPasswordValid = false
            this.rePasswordValid = false
            return this.rePasswordValid
        } 
    },
    isUserMobile(){
        if(this.userVO.userMobile ==='' || this.userVO.userMobile.length <11){
            this.userMobileValid = true
        }else{
            this.userMobileValid = false
        }
        return this.userMobileValid 
    },

    radioChange(event){
      var selected = event.target.value
      if(selected === "signiture"){
        this.signFlag = true
        this.imgFlag = false
        this.file = null
        this.fileUploadFlag = false
      }
      else{
        this.signFlag = false
        this.imgFlag = true
        this.signData = ''
        this.fileUploadFlag = false
      }
    },
    makePath(image){
      return this.getAppBaseUrl+ '/display?fileSvrPath='+encodeURI(image)
    },
    imagUpload(parameter){
      this.file = parameter.file
      this.fileUploadFlag = true
    },
    saveSign(isEmpty, data){
      this.signData = data
      this.fileUploadFlag = true
    },
    makeBase64ToFile(base64,file_name){
      var arr = base64.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], file_name, {type:mime});
    },
    async fileCreate() {
      
      var options = {}
      const filename = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      ) + ".png";

      if(this.signFlag){
        this.file = this.makeBase64ToFile(this.signData,filename)
        options = {
          maxSizeMB: 0.2, 
          maxWidthOrHeight: 500
        }
      } else {
        options = {
          maxSizeMB: 0.2, 
          maxWidthOrHeight: 400
        }
      }
			const compressedFile = await imageCompression(this.file, options);
      var fileToUpload = new File([compressedFile], filename, {type: "image/png",lastModified: new Date(), });

      return fileToUpload
    },

    async updateUser() {
        var isRePassword = this.isRePassword()
        var isUserMobile = this.isUserMobile()

        if(isRePassword||isUserMobile){
          alert('입력된 정보를 다시 확인해주세요')
          return
        }

      if(this.userVO.userPassword !== this.rePassword){
          this.userPasswordValid = true
          this.rePasswordValid = true
          alert('비밀번호 확인이 일치하지 않습니다.')
          return
      }

      if(this.fileUploadFlag){
          var fileToUpload = await this.fileCreate();
      }

      const parameter = {
          file: fileToUpload==null?null:fileToUpload,
          userVO:this.userVO,
          context: this
      }

      if(this.userVO.userAuthorityId !== '3' && this.userVO.userAuthorityId !== '4'){
        this.userVO.userAccountList = []
      }

      this.$store.dispatch("REQUEST_USERINFO_UPDATE", parameter).then(data => {
          this.$emit("modalClose")
          this.loginUserModify = false
      })
    },
    complete(){ 
      this.$store.commit("REQUEST_USER_BUSINESS_MAPP_INIT");
        this.loginUserModify = false
    },
  },
}
</script>
<style>
  .disable {
    background-color: #d8dbe0 !important;
  }
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
  