import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'


import { VueSignaturePad } from "vue-signature-pad";


import mitt from 'mitt'
const emitter = mitt();

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.config.globalProperties.$axios = axios
axios.defaults.withCredentials = false

app.component('Datepicker', Datepicker)
app.component("VueSignaturePad", VueSignaturePad);
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
