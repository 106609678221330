import axios from "axios";
import store from '@/store'

let myMixin = {
	methods: {
		validationFlag(val) {
			if(val === ''){
				return true;
			}else{
				alert(val + "을(를) 입력해 주세요.");
				return false;
			}

		},
		crudExist(menuItems,keyword) {
				
			for(let i=0;i<menuItems.length;i++){
				if(menuItems[i].menuLink === null){
					for(let j=0;j<menuItems[i].subMenuList.length;j++){
						if(menuItems[i].subMenuList[j].menuLink === keyword){
						return menuItems[i].subMenuList[j]
						}
					}
				}else{
					if(menuItems[i].menuLink === keyword){
						return menuItems[i]
					}
				}

			}
			return {}
				

		},
		feedbackInvalidFlag(data) {
			if (data === '') {
				return true
			}else {
				return false
			}
		},
		feedbackInvalidMobileFlag(data) {
			if(data === null){
				return false
			}
			if (data === '' || data.length < 11) {
				return true
			  } else {
				return false
			  }
		},
		nvl(data){
			if(data==null){
				return ''
			}else {
				return data
			}
		},
		asInstPdfFlagExist(asInstPdfFlag){
			debugger
			if(asInstPdfFlag === 1){
				return true
			  }
			  if(asInstPdfFlag === 2){
				return true
			  }
			  if(asInstPdfFlag === 3){
				return true
			  }
			  return false
		},
		asInstPdfFlagTxt(asInstPdfFlag){
		  if(asInstPdfFlag === 1){
			return '재설치'
		  }
		  if(asInstPdfFlag === 2){
			return '변경설치'
		  }
		  if(asInstPdfFlag === 3){
			return '신규설치'
		  }
		  return ''
		}
	}
};
export default myMixin;