import axios from "axios";

export function sendMultipartFilePost(url, formData) {
	return new Promise((resolve, reject) => {
		axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data',"accept-charset":"UTF-8"} })
			.then((response) => {
				resolve(response)
			}).catch((e) => {
				alert({
					title: "",
					message: '파일 전송에 실패하였습니다. 확인 후 다시 이용해 주세요.',
					okButtonText: "확인"
				})
				reject(e)
			})
	})
}