
import {
    requestInstExternalFinishList,
    requestInstExternalFinishSelect,
	requestInstExternalPdfComfirmUpdate,
	requestInstExternalPdfComfirmsUpdate,
	requestInstExternalCompleteListSelect,
	requestInstExternalCompletePdfUpdate,
	requestInstExternalCompletePdfComfirmUpdate,
	requestAsExternalFinishSelect,
	requestAsExternalPdfComfirmUpdate
} from "@/apis/external_api";

import router from "@/router";



const state = {
    instExternalFinishColumn: [
        { title: '이름', key: 'instSubjectName', class: 'common-table-text left common-table-width-20' },
        { title: '전화번호', key: 'instSubjectMobile', class: 'common-table-text left common-table-width-20' },
        { title: '주소', key: 'instAddr', class: 'common-table-text left common-table-width-40' },
		{ title: '완료일', key: 'instFinishDtime', class: 'common-table-text center common-table-width-20' },
    ],
    instExternalFinishItems:[],
    instExternalFinishItem:{},
	asExternalFinishItem:{},
	instExternalCompleteListItem:{},
	instExternalFileManagerPdfJpgItem:{}
};

const getters = {
    getInstExternalFinishColumn(state){
        return state.instExternalFinishColumn
    },

    getInstExternalFinishExternalFinishItems(state){
        return state.instExternalFinishItems
    },
    
    getInstExternalFinishItem(state){
        return state.instExternalFinishItem
    },
	getAsExternalFinishItem(state){
		return state.asExternalFinishItem
	},

	getInstExternalCompleteList(state){
        return state.instExternalCompleteListItem
    },
	getInstExternalFileManagerPdfJpgItem(state){
		return state.instExternalFileManagerPdfJpgItem
	}

};


const mutations = {
	REQUEST_INST_EXTERNAL_FINISH_LIST(state,data){
		state.instExternalFinishItems =  data.data
	},
	REQUEST_INST_EXTERNAL_FINISH_LIST_INIT(state){
		state.instExternalFinishItems = []
	},
    REQUEST_INST_EXTERNAL_FINISH_SELECT(state,data){
		
        state.instExternalFinishItem = data.data
    },
	REQUEST_INST_EXTERNAL_FINISH_SELECT_INIT(state){
        state.instExternalFinishItem = {}
	},
    REQUEST_AS_EXTERNAL_FINISH_SELECT(state,data){
		
        state.asExternalFinishItem = data.data
    },
	REQUEST_AS_EXTERNAL_FINISH_SELECT_INIT(state){
        state.asExternalFinishItem = {}
	},

	
	REQUEST_INST_EXTERNAL_COMPLETELIST_SELECT(state,data){
		state.instExternalCompleteListItem = data.data
	},
	REQUEST_INST_EXTERNAL_COMPLETELIST_SELECT_INIT(state){
		state.instExternalCompleteListItem = {}
	},
	REQUEST_INST_EXTERNAL_COMPLETE_PDF_UPDATE(state,data){
		state.instExternalFileManagerPdfJpgItem = data.data
	},
	REQUEST_INST_EXTERNAL_COMPLETE_PDF_COMFIRM_UPDATE(state,data){
		state.instExternalFileManagerPdfJpgItem = data.data
	}
};

const actions = {
	async REQUEST_INST_EXTERNAL_FINISH_LIST(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestInstExternalFinishList(parameter.value);
			if(response.status === 200){
				context.commit('REQUEST_INST_EXTERNAL_FINISH_LIST',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_INST_EXTERNAL_FINISH_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstExternalFinishSelect(parameter.value,parameter.value2);
			if(response.status === 200){
				context.commit('REQUEST_INST_EXTERNAL_FINISH_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_AS_EXTERNAL_FINISH_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsExternalFinishSelect(parameter.value,parameter.value2);
			if(response.status === 200){
				context.commit('REQUEST_AS_EXTERNAL_FINISH_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	async REQUEST_INST_EXTERNAL_PDF_COMFIRM_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstExternalPdfComfirmUpdate(parameter)
			if (response.status ===201) {
				alert("설치 점검표 서명이 정상적으로 처리되었습니다.")
				parameter.context.complete(response.data.data);
			} 
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_AS_EXTERNAL_PDF_COMFIRM_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsExternalPdfComfirmUpdate(parameter)
			if (response.status ===201) {
				alert("설치 점검표 서명이 정상적으로 처리되었습니다.")
				parameter.context.complete(response.data.data);
			} 
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	
	async REQUEST_INST_EXTERNAL_PDF_COMFIRMS_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try {
		const response = await requestInstExternalPdfComfirmsUpdate(parameter)
			if (response.status ===201) {
				parameter.context.complete(response.data.data);
			} 
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_INST_EXTERNAL_COMPLETELIST_SELECT(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestInstExternalCompleteListSelect(parameter.value);
			if(response.status === 200){
				context.commit('REQUEST_INST_EXTERNAL_COMPLETELIST_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_INST_EXTERNAL_COMPLETE_PDF_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstExternalCompletePdfUpdate(parameter.searchCompletePdf);
			if(response.status === 201){
				context.commit('REQUEST_INST_EXTERNAL_COMPLETE_PDF_UPDATE',response.data);
				parameter.context.complete();
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_INST_EXTERNAL_COMPLETE_PDF_COMFIRM_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstExternalCompletePdfComfirmUpdate(parameter)
			if (response.status ===201) {
				context.commit('REQUEST_INST_EXTERNAL_COMPLETE_PDF_COMFIRM_UPDATE',response.data);
				alert("설치 완료표 서명이 정상적으로 처리되었습니다.")
				parameter.context.complete(response.data.data);
			} 
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING')
	},
};

export default { mutations, state, actions, getters };