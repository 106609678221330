import axios from 'axios'

function requestNoticePage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/page`, parameter)
}
function requestNoticeSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/select`, parameter)
}
function requestNoticeFileDownload(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/file/download`, parameter, {responseType: 'blob'})
}
function requestNoticeHitsUp(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/hits-update`,parameter)
}

export {
    requestNoticePage,
    requestNoticeSelect,
    requestNoticeFileDownload,
    requestNoticeHitsUp
}