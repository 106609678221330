import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store";

import DefaultLayout from '@/layouts/DefaultLayout'

const onlyAuth = (to, from, next) => {
  
	if (!store.getters.isAuthenticated) {
    // 아직 로그인이 안 되있는 유저니까 막아야 한다.
		next("/pages/login")
	} else {
    
    const paths = to.path.split('/')
    if(paths.length>3){
      if(paths[1]=== 'inst'){
        const parameter={
          searchInstCount:{}
        }
        store.dispatch("REQUEST_INST_COUNT_LIST",parameter);
      }
      if(paths[1]=== 'as'){
        const parameter={
          searchAsCount:{}
        }
        store.dispatch("REQUEST_AS_COUNT_LIST",parameter);
      }
    }
		next()
	}
}

const onlyNotAuth = (to, from, next) => {
	if (store.getters.isAuthenticated) {
    // 아직 로그인이 안 되있는 유저니까 막아야 한다.
		next("/")
	} else {
		next()
	}
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        beforeEnter: onlyAuth,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      
      {
        path: '/inst',
        name: 'inst',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inst/set/page',
        children: [
          {
            path: 'set',
            name: 'instSet',
            redirect: '/inst/set/page',
            children: [
              {        
                path: 'page',
                name: 'instSetPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/set/Page'),
              },
              {        
                path: 'detail',
                name: 'instSetDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/set/Detail'),
              },
            ]
          },
          {
            path: 'ing',
            name: 'instIng',
            redirect: '/inst/ing/page',
            children: [
              {        
                path: 'page',
                name: 'instIngPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/ing/Page'),
              },
              {        
                path: 'detail',
                name: 'insgIngDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/ing/Detail'),
              },
              {        
                path: 'result1',
                name: 'instIngResult1',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/ing/Result1'),
              },
              {        
                path: 'result2',
                name: 'instIngResult2',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/ing/Result2'),
              },
            ]
          },
          {
            path: 'finish',
            name: 'instFinish',
            redirect: '/inst/finish/page',
            children: [
              {        
                path: 'page',
                name: 'instFinishPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/finish/Page'),
              },
              {        
                path: 'detail',
                name: 'instFinishDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/finish/Detail'),
              },
            ]
          }, 
          {
            path: 'cancel',
            name: 'instCancel',
            redirect: '/inst/cancel/page',
            children: [
              {        
                path: 'page',
                name: 'instCancelPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/cancel/Page'),
              },
              {        
                path: 'detail',
                name: 'instCancelDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/cancel/Detail'),
              },
            ]
          },      
        ],
      },

      {
        path: '/as',
        name: 'as',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inst/set/page',
        children: [
          {
            path: 'set',
            name: 'asSet',
            redirect: '/as/set/page',
            children: [
              {        
                path: 'page',
                name: 'asSetPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/set/Page'),
              },
              {        
                path: 'detail',
                name: 'asSetDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/set/Detail'),
              },
            ]
          },
          {
            path: 'ing',
            name: 'asing',
            redirect: '/as/ing/page',
            children: [
              {        
                path: 'page',
                name: 'asIngPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/ing/Page'),
              },
              {        
                path: 'detail',
                name: 'asIngDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/ing/Detail'),
              },
              {        
                path: 'result1',
                name: 'asIngResult1',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/ing/Result1'),
              },
              {        
                path: 'result2',
                name: 'asIngResult2',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/ing/Result2'),
              },
            ]
          }, 
          {
            path: 'finish',
            name: 'asFinish',
            redirect: '/as/finish/page',
            children: [
              {        
                path: 'page',
                name: 'asFinishPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/finish/Page'),
              },
              {        
                path: 'detail',
                name: 'asFinishDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/finish/Detail'),
              },
            ]
          }, 
          {
            path: 'cancel',
            name: 'asCancel',
            redirect: '/as/cancel/page',
            children: [
              {        
                path: 'page',
                name: 'asCancelPage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/cancel/Page'),
              },
              {        
                path: 'detail',
                name: 'asCancelDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/cancel/Detail'),
              },
            ]
          }, 
        ],
      },

      {
        path: '/board',
        name: 'board',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/board/notice/page',
        children: [
          {
            path: 'notice',
            name: 'boardNotice',
            redirect: '/board/notice/page',
            children: [
              {        
                path: 'page',
                name: 'boardNoticePage',
                beforeEnter: onlyAuth,
                component: () => import('@/views/board/notice/Page'),
              },
              {        
                path: 'detail',
                name: 'boardNoticeDetail',
                beforeEnter: onlyAuth,
                component: () => import('@/views/board/notice/Detail'),
              },
            ]
          },
        ],
      },
    ], 
  },
  {
    path:'/external',
    redirect:'/pages/404',
    name:'external',
    render() {
      return h(resolveComponent('router-view'))
    },
    children: [
      {
        path: 'signatureList',
        name: 'signatureList',
        component: () => import('@/views/external/externalSignList'),
      },
      {
        path: 'signature',
        name: 'signature',
        component: () => import('@/views/external/externalSign'),
      },
      {
        path: 'assignature',
        name: 'assignature',
        component: () => import('@/views/external/externalAsSign'),
      },
      
      {
        path: 'completeList',
        name: 'completeList',
        component: () => import('@/views/external/externalCompleteList'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        beforeEnter: onlyNotAuth,
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        beforeEnter: onlyNotAuth,
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
