const pageType = 'cancel'
import {
    requestAsPage,
    requestAsSelect,
	requestAsUpdate,
} from "@/apis/as_api";

import router from "@/router";



const state = {
    asCancelStateCd:'STE004', //진행코드
    asCancelColumn: [
        { title: '이름', key: 'asSubjectName', class: 'common-table-text common-table-width-15' },
        { title: '전화번호', key: 'asSubjectMobile', class: 'common-table-text common-table-width-25' },
        { title: '주소', key: 'asAddr', class: 'common-table-text common-table-width-40' },
		{ title: '취소일', key: 'asCancelDtime', class: 'common-table-text common-table-width-20' },
    ],
    asCancelItems:[],
    asCancelTotalCount:0,
    asCancelItem:{},
};

const getters = {
    getAsCancelStateCd(state){
        return state.asCancelStateCd
    },
    getAsCancelColumn(state){
        return state.asCancelColumn
    },

    
    getAsCancelCancelItems(state){
        return state.asCancelItems
    },
    
    getAsCancelTotalCount(state){
        return state.asCancelTotalCount
    },
    getAsCancelItem(state){
        return state.asCancelItem
    },

};


const mutations = {
	//대상자
	REQUEST_AS_CANCEL_PAGE(state,data){
		state.asCancelItems = data.data
		state.asCancelTotalCount = data.totalCount
	},
	REQUEST_AS_CANCEL_PAGE_INIT(state){
		state.asCancelItems = []
		state.asCancelTotalCount = 0
	},
    REQUEST_AS_CANCEL_SELECT(state,data){
        state.asCancelItem = data.data
		state.asCancelItem.asRequestDesc = JSON.parse(state.asCancelItem.asRequestDesc)
		let asRequestDescText = '';
		for(let i=0;i<state.asCancelItem.asRequestDesc.length;i++){
			asRequestDescText += state.asCancelItem.asRequestDesc[i].title + ' : ' + state.asCancelItem.asRequestDesc[i].value  + '\n'
		}
		state.asCancelItem.asRequestDescText = asRequestDescText
    },
	REQUEST_AS_CANCEL_SELECT_INIT(state){
        state.asCancelItem = {}
	},
};

const actions = {
	async REQUEST_AS_CANCEL_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestAsPage(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_CANCEL_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_AS_CANCEL_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsSelect(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_CANCEL_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_AS_CANCEL_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsUpdate(parameter.searchAs,pageType);
			if(response.status === 201){
				parameter.context.complete(parameter)
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	
};

export default { mutations, state, actions, getters };