const pageType = 'set'
import {
    requestAsPage,
    requestAsSelect,
	requestAsUpdate,
	requestAsCountList,
	requestBusinessAsCodeSelect
} from "@/apis/as_api";

import router from "@/router";



const state = {
    asSetStateCd:'STE001', //진행코드
    asSetColumn: [
        { title: '이름', key: 'asSubjectName', class: 'common-table-text left common-table-width-15' },
        { title: '전화번호', key: 'asSubjectMobile', class: 'common-table-text left common-table-width-25' },
        { title: '주소', key: 'asAddr', class: 'common-table-text left common-table-width-40' },
		{ title: '등록일', key: 'asOccurDtime', class: 'common-table-text left common-table-width-20' },
    ],
    asSetItems:[],
    asSetTotalCount:0,
    asSetItem:{},
	asCountItems:[],

	businessAsFirstCodesForSelectBox:[],
	businessAsSecondCodesForSelectBox:[],
	businessAsThirdCodesForSelectBox:[],
};

const getters = {
    getAsSetStateCd(state){
        return state.asSetStateCd
    },
    getAsSetColumn(state){
        return state.asSetColumn
    },

    
    getAsSetSetItems(state){
        return state.asSetItems
    },
    
    getAsSetTotalCount(state){
        return state.asSetTotalCount
    },
    getAsSetItem(state){
        return state.asSetItem
    },
	getAsCountItems(state){
		return state.asCountItems
	},
	getBusinessAsFirstCodeForSelectBox(state){
		return state.businessAsFirstCodesForSelectBox
	},
	getBusinessAsSecondCodeForSelectBox(state){
		return (keyword) => state.businessAsSecondCodesForSelectBox.filter(item =>{
			return keyword === item.codeParentCd
		});
	},
	getBusinessAsThirdCodeForSelectBox(state){
		return (keyword) => state.businessAsThirdCodesForSelectBox.filter(item =>{
			return keyword === item.codeParentCd
		});
	},

};


const mutations = {
	//대상자
	REQUEST_AS_SET_PAGE(state,data){
		state.asSetItems = data.data
		state.asSetTotalCount = data.totalCount
	},
	REQUEST_AS_SET_PAGE_INIT(state){
		state.asSetItems = []
		state.asSetTotalCount = 0
	},
    REQUEST_AS_SET_SELECT(state,data){
        state.asSetItem = data.data
		state.asSetItem.asRequestDesc = JSON.parse(state.asSetItem.asRequestDesc)
		let asRequestDescText = '';
		for(let i=0;i<state.asSetItem.asRequestDesc.length;i++){
			asRequestDescText += state.asSetItem.asRequestDesc[i].title + ' : ' + state.asSetItem.asRequestDesc[i].value  + '\n'
		}
		state.asSetItem.asRequestDescText = asRequestDescText
    },
	REQUEST_AS_SET_SELECT_INIT(state){
        state.asSetItem = {}
	},
	REQUEST_AS_COUNT_LIST(state,data){
		state.asCountItems =  data.data
	},
	REQUEST_AS_COUNT_LIST_INIT(state){
		state.asCountItems = []
	},
	REQUEST_BUSINESS_AS_CODE_SELECT(state,data){
		state.businessAsFirstCodes = data.data.firstCodeList
		state.businessAsSecondCodes = data.data.secondCodeList
		state.businessAsThirdCodes = data.data.thirdCodeList

		const firstCodeList = data.data.firstCodeList
		state.businessAsFirstCodesForSelectBox=[];
		for(let i=0; i<firstCodeList.length; i++) {
			if(firstCodeList[i].codeUseYn){
				state.businessAsFirstCodesForSelectBox.push({
					label: firstCodeList[i].codeName,
					value: firstCodeList[i].codeCd,
				});
			}
		}
		const secondCodeList = data.data.secondCodeList
		state.businessAsSecondCodesForSelectBox=[];
		for(let i=0; i<secondCodeList.length; i++) {
			if(secondCodeList[i].codeUseYn){
				state.businessAsSecondCodesForSelectBox.push({
					label: secondCodeList[i].codeName,
					value: secondCodeList[i].codeCd,
					codeParentCd: secondCodeList[i].codeParentCd
				});
			}
		}
		const thirdCodeList = data.data.thirdCodeList
		state.businessAsThirdCodesForSelectBox=[];
		for(let i=0; i<thirdCodeList.length; i++) {
			if(thirdCodeList[i].codeUseYn){
				state.businessAsThirdCodesForSelectBox.push({
					label: thirdCodeList[i].codeName,
					value: thirdCodeList[i].codeCd,
					codeParentCd: thirdCodeList[i].codeParentCd
				});
			}
		}
	},
};

const actions = {
	async REQUEST_AS_SET_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestAsPage(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_SET_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_AS_SET_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsSelect(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_SET_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_AS_SET_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsUpdate(parameter.searchAs,pageType);
			if(response.status === 201){
				parameter.context.complete(parameter)
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	async REQUEST_AS_COUNT_LIST(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsCountList(parameter.searchAsCount);
			if(response.status === 200){
				context.commit('REQUEST_AS_COUNT_LIST',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	async REQUEST_BUSINESS_AS_CODE_SELECT(context){
		context.commit('START_LOADING');
		try{
			const response = await requestBusinessAsCodeSelect();
			if(response.status === 200){
				context.commit('REQUEST_BUSINESS_AS_CODE_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)                
		}
		context.commit('END_LOADING');
	},
};

export default { mutations, state, actions, getters };