const pageType = 'ing'
import {
    requestAsPage,
    requestAsSelect,
	requestAsUpdate,
	requestAsAndImgUpdate,
	requestAsPdfUpdate
} from "@/apis/as_api";

import router from "@/router";



const state = {
    asIngStateCd:'STE002', //진행코드
    asIngColumn: [
        { title: '이름', key: 'asSubjectName', class: 'common-table-text common-table-width-15' },
        { title: '전화번호', key: 'asSubjectMobile', class: 'common-table-text common-table-width-25' },
        { title: '주소', key: 'asAddr', class: 'common-table-text common-table-width-40' },
		{ title: '진행일', key: 'asUserAssignDtime', class: 'common-table-text common-table-width-20' },
    ],
    asIngItems:[],
    asIngTotalCount:0,
    asIngItem:{},
};

const getters = {
    getAsIngStateCd(state){
        return state.asIngStateCd
    },
    getAsIngColumn(state){
        return state.asIngColumn
    },

    
    getAsIngIngItems(state){
        return state.asIngItems
    },
    
    getAsIngTotalCount(state){
        return state.asIngTotalCount
    },
    getAsIngItem(state){
        return state.asIngItem
    },
};


const mutations = {
	//대상자
	REQUEST_AS_ING_PAGE(state,data){
		state.asIngItems = data.data
		state.asIngTotalCount = data.totalCount
	},
	REQUEST_AS_ING_PAGE_INIT(state){
		state.asIngItems = []
		state.asIngTotalCount = 0
	},
    REQUEST_AS_ING_SELECT(state,data){
        state.asIngItem = data.data
		state.asIngItem.asRequestDesc = JSON.parse(state.asIngItem.asRequestDesc)
		let asRequestDescText = '';
		for(let i=0;i<state.asIngItem.asRequestDesc.length;i++){
			asRequestDescText += state.asIngItem.asRequestDesc[i].title + ' : ' + state.asIngItem.asRequestDesc[i].value  + '\n'
		}
		state.asIngItem.asRequestDescText = asRequestDescText
    },
	REQUEST_AS_ING_SELECT_INIT(state){
        state.asIngItem = {}
	},
};

const actions = {
	async REQUEST_AS_ING_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestAsPage(parameter.searchAs,pageType);
			if(response.status === 200){
				context.commit('REQUEST_AS_ING_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_AS_ING_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsSelect(parameter.searchAs,pageType);
			if(response.status === 200){
				if(parameter.context != undefined){
					parameter.context.completeGetData(response.data)
				}
				context.commit('REQUEST_AS_ING_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_AS_ING_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsUpdate(parameter.searchAs,pageType);
			if(response.status === 201){
				parameter.context.complete(parameter)
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	async REQUEST_AS_AND_IMG_UPDATE(context,parameter){
		context.commit('START_LOADING');
		requestAsAndImgUpdate(parameter).then((response) => {
			if (response.status ===201) {
				parameter.context.complete(response.data.data);
			} 
		}).catch((e) => {
			console.log(e.response.data.resultMsg)
		})
		context.commit('END_LOADING')
	},
	async REQUEST_AS_ING_PDF_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestAsPdfUpdate(parameter.instPdf);
			if(response.status === 201){
				parameter.context.completeBtn1Click();
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
	
};

export default { mutations, state, actions, getters };