import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { sendMultipartFilePost } from '@/utils/multipart-helper'

function requestAsPage(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/page`,parameter)
}

function requestAsSelect(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/select`,parameter)
}

function requestAsUpdate(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/update`,parameter)
}


function requestAsCountList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/count/list`,parameter)
}


function requestAsAndImgUpdate(parameter){
    const formData = new FormData()
    for ( var key in parameter.searchAs) {
        if(parameter.searchAs[key]!==null){
            formData.append(key, parameter.searchAs[key]);
        }
    }
    const noneDeleteFileList = []
    for(let i=0;i<parameter.beforeFiles.length;i++){
        if(!parameter.beforeImges[i].loadFile){
            formData.append('beforeFiles'+i, parameter.beforeFiles[i])
        }else{
            noneDeleteFileList.push(parameter.beforeImges[i].fileManagerNo)
        }
    }
    for(let i=0;i<parameter.afterFiles.length;i++){
        if(!parameter.afterImges[i].loadFile){
            formData.append('afterFiles'+i, parameter.afterFiles[i])
        }else{
            noneDeleteFileList.push(parameter.afterImges[i].fileManagerNo)
        }
    }
    
    formData.append('noneDeleteFileList', noneDeleteFileList)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/as/ing/as_and_img/update`, formData)
}
function requestBusinessAsCodeSelect(){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business-code/as/select`)
}



function requestAsPdfUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/ing/pdf/update`,parameter)
}


export {
    requestAsPage,
    requestAsSelect,
    requestAsUpdate,
    requestAsCountList,
    requestAsAndImgUpdate,
    requestBusinessAsCodeSelect,
    requestAsPdfUpdate
}