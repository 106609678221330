import { createStore } from 'vuex'
import external from './external'
import common from './common'
import inst_ing from './inst_ing'
import inst_set from './inst_set'
import inst_cancel from './inst_cancel'
import inst_finish from './inst_finish'
import notice from './notice'

import as_set from './as_set'
import as_ing from './as_ing'
import as_cancel from './as_cancel'
import as_finish from './as_finish'
import user from './user'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: true,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    common,
    inst_ing,
    inst_set,
    inst_cancel,
    inst_finish,
    as_cancel,
    as_ing,
    as_finish,
    as_set,
    user,
    external,
    notice
  },
})
