<template>
  <CAvatar :src="backwordImg" size="md" @click="backword()" />
</template>

<script>
import backwordImg from '@/assets/images/backwordImg.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {
      backwordImg: backwordImg,
    }
  },
  methods: {
    backword(){
      this.$router.go(-1)
    },
  },
}
</script>
