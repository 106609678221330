import axios from 'axios'
import store from '@/store'
import router from '@/router'

import { setTokenInLocalStorage } from '@/utils/oauth'

/*
    모든 요청 전 header에 access_token을 담아 전송한다.
*/
axios.interceptors.request.use(
    config => {
        
        let accessToken = localStorage.getItem('access_token')
        let userBusinessSchema = localStorage.getItem('userBusinessSchema')
        let userAccountId = localStorage.getItem('userAccountId')
        let privacyYn = localStorage.getItem('privacyYn')
        
        if (accessToken !== null && accessToken !== undefined) {
            config.headers.Authorization = accessToken

            if(config.data!==null && config.data!== undefined){
               //현재 프로젝트에 맞게 추후 수정
            }

            if(config.params!==null && config.params !== undefined){
             //현재 프로젝트에 맞게 추후 수정
            }
        }
        if (userBusinessSchema !== null && userBusinessSchema !== undefined) {
            config.headers.userBusinessSchema = userBusinessSchema
            config.headers.privacyYn = privacyYn
        }
        if (userAccountId !== null && userAccountId !== undefined) {
            config.headers.userAccountId = userAccountId
        }

        config.headers.mobileYnObject = 1

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

/*
    만료된 access_token으로 요청시 Access token exprited가 발생하면 refresh 토큰으로 새로운 토큰을 받는다.
*/
axios.interceptors.response.use(
    response => {
        
        return response
    },
    async function (error) {
        
        const errorAPI = error.config;
        if (!error.response) {
            router.push('/pages/404')
        }
        let errorData = error.response.data
        let status = errorData.status
        if (status === 500) {
            router.push('/pages/500')
        }
        if (status === 404) {
            router.push('/pages/404')
        }
        
        if (status === 400) {
            alert(errorData.resultMsg);
        }
        
        if (status === 401) {
            if(errorData.code === 'A003'){
                if(await attemptRefreshToken()){
                    return await axios(errorAPI);
                }
            }
            if (errorData.code === 'A002') {
                store.commit('LOGOUT_WITH_TOKEN_INVALIDE')
                return Promise.reject(error)
            }
            
            if (errorData.code === 'A001') {
                store.commit('LOGOUT_WITH_TOKEN_INVALIDE')
                return Promise.reject(error)
            }
        }
        return Promise.reject(error)
    }
)

async function attemptRefreshToken() {
    const response = await requestRefreshToken();
    
    if(response.status === 200){
        setTokenInLocalStorage (response.data)
        return true
    }
    return false
}

const deleteAccessTokenInHeader = () => {
    axios.defaults.headers.common['Authorization'] = null
}

function requestRefreshToken() {
    const parameter = {
        refreshToken: localStorage.getItem('refresh_token')
    }
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/refreshToken`, parameter)
}

function requestUserDuplicateIdCheck(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/duplicateId/select`,parameter)
}
function requestAuthJoinCode(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/joinCode/select`,parameter)
}

function requestCommonData() {
    return axios.get(`${process.env.VUE_APP_BASEURL}/common/select`)
}

function requestDashboardSelect(){    
    return axios.get(`${process.env.VUE_APP_BASEURL}/common/dashboard/select`)
}
function requestAccountList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auth/account/list`, parameter)
}
function requestBusinessCodeSelect(){
    return axios.post(`${process.env.VUE_APP_BASEURL}/common/business-code/select`)
}

export {
    requestUserDuplicateIdCheck,
    requestAuthJoinCode,
    deleteAccessTokenInHeader,
    requestCommonData,
    requestDashboardSelect,
    requestAccountList,
    requestBusinessCodeSelect
}