import axios from 'axios'

function requestUserInsert(parameter) {
    const formData = new FormData()
    for ( var key in parameter.userVO ) {
        if(parameter.userVO[key]!==null){
            if(key == 'userBusinessMappingList'){
                parameter.userVO[key].forEach((value, index, array)=>{
                    formData.append("userBusinessMappingList["+index+"].businessSchemaName", value.value)
                  })
            }else if(key == 'userAccountList'){
                parameter.userVO[key].forEach((value, index, array)=>{
                    formData.append("userBusinessMappingList["+index+"].accountId", value)
                  })
            } else{
                formData.append(key, parameter.userVO[key])
            }
        }
    }
    formData.append('file', parameter.file)
    return axios.post(`${process.env.VUE_APP_BASEURL}/user/insert`, formData)
}

function requestLogin(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/login`, parameter)
}

function requestFindUser(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/find-user`, parameter)
}

function requestFindUserId(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/find-userid`, parameter)
}

function requesUserInfoBusinessList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/userInfo/business/list`, parameter)
}

function requestUserInfoUpdate(parameter){
    const formData = new FormData()
    for ( var key in parameter.userVO ) {
        if(parameter.userVO[key]!==null){
            if(key == 'userBusinessMappingList'){
                continue
            }else if(key == 'userAccountList'){
                continue
            } else{
                formData.append(key, parameter.userVO[key])
            }
        }
    }
    formData.append('file', parameter.file)
    return axios.post(`${process.env.VUE_APP_BASEURL}/userInfo/update`, formData)
}
function requestUserInfoSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/userInfo/select`, parameter)
}
function requestLogout() {
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/logout`)
}

export {
    requestUserInsert,
    requestLogin,
    requestFindUser,
    requestFindUserId,
    requesUserInfoBusinessList,
    requestUserInfoUpdate,
    requestUserInfoSelect,
    requestLogout
}