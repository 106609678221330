import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { sendMultipartFilePost } from '@/utils/multipart-helper'

function requestInstExternalFinishList(parameter){
    return axios.get(`${process.env.VUE_APP_BASEURL}/external/inst_finish/list?value=${parameter}`)
}
function requestInstExternalFinishSelect(value,value2){
    return axios.get(`${process.env.VUE_APP_BASEURL}/external/inst_finish/select?value=${value}&value2=${value2}`)
}

function requestAsExternalFinishSelect(value,value2){
    return axios.get(`${process.env.VUE_APP_BASEURL}/external/as_finish/select?value=${value}&value2=${value2}`)
}


function requestInstExternalPdfComfirmUpdate(parameter){
    
    const formData = new FormData()
    for ( var key in parameter.inst ) {
        if(parameter.inst[key]!==null){
            formData.append(key, parameter.inst[key]);
        }
    }
    formData.append('file', parameter.file)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/external/inst_finish_pdf_comfirm/update`, formData)
}


function requestAsExternalPdfComfirmUpdate(parameter){
    
    const formData = new FormData()
    for ( var key in parameter.as ) {
        if(parameter.as[key]!==null){
            formData.append(key, parameter.as[key]);
        }
    }
    formData.append('file', parameter.file)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/external/as_finish_pdf_comfirm/update`, formData)
}

function requestInstExternalPdfComfirmsUpdate(parameter){
    
    const formData = new FormData()
    for(let i=0;i<parameter.insts.length;i++){
        for ( var key in parameter.insts[i] ) {
            if(parameter.insts[i][key]!==null){
                formData.append("searchInstList[" + i + "]." + key, parameter.insts[i][key]);
            }
        }
        
    }
   
    formData.append('file', parameter.file)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/external/inst_finish_pdf_comfirms/update`, formData)
}
function requestInstExternalCompleteListSelect(parameter){
    return axios.get(`${process.env.VUE_APP_BASEURL}/external/completePdf/select?value=${parameter}`)
}
function requestInstExternalCompletePdfUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/external/completePdf/update`, parameter)
}
function requestInstExternalCompletePdfComfirmUpdate(parameter){
    const formData = new FormData()
    for ( var key in parameter.searchCompletePdf ) {
        if(parameter.searchCompletePdf[key]!==null){
            formData.append(key, parameter.searchCompletePdf[key]);
        }
    }
    formData.append('file', parameter.file)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/external/compltetPdf_comfirms/update`, formData)
}


export {
    requestInstExternalFinishList,
    requestInstExternalFinishSelect,
    requestInstExternalPdfComfirmUpdate,
    requestAsExternalPdfComfirmUpdate,
    requestInstExternalPdfComfirmsUpdate,
    requestInstExternalCompleteListSelect,
    requestInstExternalCompletePdfUpdate,
    requestInstExternalCompletePdfComfirmUpdate,
    requestAsExternalFinishSelect
}