const pageType = 'set'
import {
    requestInstPage,
    requestInstSelect,
	requestInstUpdate,
} from "@/apis/inst_api";

import router from "@/router";



const state = {
    instSetStateCd:'STE001', //진행코드
    instSetColumn: [
        { title: '이름', key: 'instSubjectName', class: 'common-table-text left common-table-width-15' },
        { title: '전화번호', key: 'instSubjectMobile', class: 'common-table-text left common-table-width-25' },
        { title: '주소', key: 'instAddr', class: 'common-table-text left common-table-width-40' },
		{ title: '등록일', key: 'instRegDtime', class: 'common-table-text left common-table-width-20' },
    ],
    instSetItems:[],
    instSetTotalCount:0,
    instSetItem:{},
};

const getters = {
    getInstSetStateCd(state){
        return state.instSetStateCd
    },
    getInstSetColumn(state){
        return state.instSetColumn
    },

    
    getInstSetSetItems(state){
        return state.instSetItems
    },
    
    getInstSetTotalCount(state){
        return state.instSetTotalCount
    },
    getInstSetItem(state){
        return state.instSetItem
    },

};


const mutations = {
	//대상자
	REQUEST_INST_SET_PAGE(state,data){
		state.instSetItems = data.data
		state.instSetTotalCount = data.totalCount
	},
	REQUEST_INST_SET_PAGE_INIT(state){
		state.instSetItems = []
		state.instSetTotalCount = 0
	},
    REQUEST_INST_SET_SELECT(state,data){
        state.instSetItem = data.data
    },
	REQUEST_INST_SET_SELECT_INIT(state){
        state.instSetItem = {}
	},
};

const actions = {
	async REQUEST_INST_SET_PAGE(context,parameter) {
		context.commit('START_LOADING');
		try{
			const response = await requestInstPage(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_SET_PAGE',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
	},
    async REQUEST_INST_SET_SELECT(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstSelect(parameter.searchInst,pageType);
			if(response.status === 200){
				context.commit('REQUEST_INST_SET_SELECT',response.data);
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
    async REQUEST_INST_SET_UPDATE(context,parameter){
		context.commit('START_LOADING');
		try{
			const response = await requestInstUpdate(parameter.searchInst,pageType);
			if(response.status === 201){
				parameter.context.complete(parameter)
			}
		}catch(e){
			console.log(e.response.data.resultMsg)
		}
		context.commit('END_LOADING');
    },
	
};

export default { mutations, state, actions, getters };