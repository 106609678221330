<template>
  <router-view />
  <CommonAlertModal :label="alertLabel" :text="alertText"/>
  <div v-if="getLoadingFlag" class="spinner_backgroud">
      <CSpinner color="warning"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonAlertModal from '@/components/common/CommonAlertModal.vue'
export default {
  name: 'App',
  components: {
    CommonAlertModal,
  },
  data() {
    return {
      alertLabel:'',
      alertText:''
    }
  },
  mounted(){
    const context = this
    this.emitter.on('alarmAlert', (text) => {
      context.alertLabel = '확인'
      context.alertText = text
      context.emitter.emit('openAlertModal')
    })
  },
  beforeUnmount(){
    this.emitter.off('alarmAlert')
  },
  computed:{
		...mapGetters(['getMenuItems','getLoadingFlag']),
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
