import {
	requestUserInsert,
    requestLogin,
    requesUserInfoBusinessList,
    requestUserInfoUpdate,
    requestUserInfoSelect,
    requestFindUser,
    requestFindUserId,
    requestLogout
} from "../apis/user_api";
import {
	deleteTokenInLocalStorage,
	setTokenInLocalStorage,
	setMemberInfoLocalStorage,
    setBusinessInfoLocalStorage
} from "@/utils/oauth";
import { deleteAccessTokenInHeader } from "../apis/common_api";

import router from "@/router";



const state = {
    accessToken: localStorage.getItem("access_token"),

    user: {
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        userAuthorityId: localStorage.getItem("userAuthorityId"),
        userBusinessMappingList: localStorage.getItem("userBusinessMappingList"),
        userBusinessSchema: localStorage.getItem("userBusinessSchema"),
        userAccountName:  localStorage.getItem("userAccountName"),
    },

    userItems:[],
    userTotalCount:0,

    userBusinessItems:[],
    userAccountItems:[],

    tempUser:[]
};


function resetToken(state) {
	deleteTokenInLocalStorage();
	deleteAccessTokenInHeader();
	state.accessToken = null;
}

const getters = {
    isAuthenticated(state) {
        return state.accessToken!==null && state.accessToken!==undefined && state.accessToken!=='';
    },
    getUserItems(state){
        return state.userItems
    },
    getUserTotalCount(state){
        return state.userTotalCount
    },
    getUserBusinessItems(state){
        return state.userBusinessItems
    },
    getUserAccountItems(state){
        return state.userAccountItems
    },
    getUser(state){
        return state.user
    },
    getLoginUserAuthorityId(state){
        return state.user.userAuthorityId
    },
    getUserBusinessList(state){
        return JSON.parse(state.user.userBusinessMappingList)
    },
    getUserBusinessSchema(state){
        return state.user.userBusinessSchema
    },
    getTempUser(state){
        return state.tempUser
    },
};


const mutations = {

    LOGIN(state,data) {
        state.user.userId = data.userId
        state.user.userName = data.userName
        state.user.userAuthorityId = data.userAuthorityId
        
        const businessList = data.userBusinessMappingList
        state.user.userBusinessMappingList=[];
        for(let i=0; i<businessList.length; i++) {
            state.user.userBusinessMappingList.push({
            label: businessList[i].businessName,
            value: businessList[i].businessSchemaName,
            });
        }
        
        state.accessToken = localStorage.getItem("access_token")
        state.user.userId= localStorage.getItem("userId")
        state.user.userName= localStorage.getItem("userName")
        state.user.userAuthorityId= localStorage.getItem("userAuthorityId")
        state.user.userBusinessMappingList= localStorage.getItem("userBusinessMappingList")
        state.user.userBusinessSchema= localStorage.getItem("userBusinessSchema")
        router.push("/");

        if(data.userTempPasswordYn){
            alert('임시비밀번호로 로그인 하셨습니다.\n비밀번호를 변경해주세요.')
        }
    },
    LOGOUT(state) {
        resetToken(state);
        router.push("/pages/login");
    },
    LOGOUT_WITH_TOKEN_INVALIDE(state) {		
        resetToken(state);
        router.push("/pages/login");
    },
    REQUEST_USER_PAGE(state,data){
        state.userItems = data.data
        state.userTotalCount = data.totalCount
    },
    REQUEST_USER_PAGE_INIT(state){
        state.userItems = []
        state.userTotalCount = 0
    },
    REQUEST_CHANGE_BUSINESS(state,data){
        const userBusinessMappingList = JSON.parse(state.user.userBusinessMappingList)
        for(let i=0;i<userBusinessMappingList.length;i++){
            if(data.value === userBusinessMappingList[i].value){
                setBusinessInfoLocalStorage(userBusinessMappingList[i])
                break;
            }
        }
        state.userBusinessSchema = localStorage.getItem("userBusinessSchema")
        router.go(router.currentRoute)
    },
    REQUEST_USER_BUSINESS_MAPP_INIT(state){
        state.userBusinessItems = []
        state.userAccountItems = []
    },
    REQUEST_USERINFO_BUSINESS_LIST(state,data){
        const businessList = data.data
        state.userBusinessItems=[];
        for(let i=0; i<businessList.length; i++) {
            state.userBusinessItems.push({
                value: businessList[i].businessSchemaName,
                label: businessList[i].businessName,
            })
            state.userAccountItems.push(businessList[i].accountId)
        }
    },
    REQUEST_FIND_USER(state,data){
        state.tempUser = data
    },
    REQUEST_FIND_USER_INIT(state,data){
        state.tempUser = null
    },
    REQUEST_FIND_USER_ID(state,data){
        state.tempUser = data
    },
};


const actions = {

    async REQUEST_USER_INSERT(context,parameter){
        context.commit('START_LOADING');
        try{
            const response = await requestUserInsert(parameter);
            if(response.status === 201){
                parameter.context.complete();
                alert("회원가입이 완료되었습니다!\n승인 받은 후 로그인 할 수 있습니다.")
            }
        }
        catch(e){
            alert("ERROR: 회원가입 실패\n관리자에게 문의해주세요")
        }
        context.commit('END_LOADING');

    },
    async REQUEST_LOGIN(context, data) {
        try {
            context.commit('START_LOADING');
            
            const response = await requestLogin(data);
            setTokenInLocalStorage(response.data);

            context.commit("LOGIN",response.data);
            context.commit('END_LOADING');
            return true;
        } catch (e) { 
            console.log(e.response.data.resultMsg)
            context.commit('END_LOADING');
            return false;
        }
    },
    async REQUEST_USERINFO_BUSINESS_LIST(context,parameter) {
        context.commit('START_TABLE_LOADING');
        try{
            const response = await requesUserInfoBusinessList(parameter.searchUserVO);
            if(response.status === 200){
                context.commit('REQUEST_USERINFO_BUSINESS_LIST',response.data);
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },
    async REQUEST_USERINFO_SELECT(context,parameter) {
        context.commit('START_LOADING');
        try{
            const response = await requestUserInfoSelect(parameter.searchUserVO);
            if(response.status === 200){
                return response.data
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },
    async REQUEST_USERINFO_UPDATE(context,parameter){
        context.commit('START_LOADING');
        try{
            const response = await requestUserInfoUpdate(parameter);
            if(response.status === 201){
                parameter.context.complete();
                alert("사용자정보 수정을 성공하였습니다.")
            }
        }
        catch(e){
            alert("ERROR: 사용자정보 수정 실패\n관리자에게 문의해주세요")
        }
        context.commit('END_LOADING');
    },
    async REQUEST_FIND_USER(context,parameter) {
        context.commit('START_LOADING');
        try{
            const response = await requestFindUser(parameter.loginVO);
            if(response.status === 200){
                context.commit("REQUEST_FIND_USER", response.data);
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },
    async REQUEST_FIND_USER_ID(context,parameter) {
        context.commit('START_LOADING');
        try{
            const response = await requestFindUserId(parameter.loginVO);
            if(response.status === 200){
                context.commit("REQUEST_FIND_USER_ID", response.data);
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },
    async REQUEST_LOGOUT(context) {
        context.commit('START_LOADING');
        try{
            const response = await requestLogout();
            if(response.status === 200){
                context.commit('LOGOUT');
                alert('로그아웃 되었습니다.')
            }
        }catch(e){
            console.log(e.response.data.resultMsg)
        }
        context.commit('END_LOADING');
    },

}
export default { mutations, state, actions, getters };